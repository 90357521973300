(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/invite-members/assets/javascripts/services/generate-invite-link.js') >= 0) return;  svs.modules.push('/components/marketplace-data/invite-members/assets/javascripts/services/generate-invite-link.js');
"use strict";


const {
  jupiter
} = svs.core;
const generateInviteLink = (groupId, callback) => {
  jupiter.post("/marketplace/1/groups/".concat(groupId, "/invitationlinks"), response => {
    callback(undefined, {
      token: response.CreateGroupInvitationLinkReply.invitationToken,
      expires: new Date(response.CreateGroupInvitationLinkReply.invitationValidUntil)
    });
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.inviteMembers.services.generateInviteLink', generateInviteLink);

 })(window);