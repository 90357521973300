(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/invite-members/assets/javascripts/invite-members-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/invite-members/assets/javascripts/invite-members-actions.js');
"use strict";


const {
  actions: dialogActions
} = svs.components.marketplaceData.dialogWrapper;
const {
  errorCodeHandler
} = svs.components.marketplaceData;
const {
  services
} = svs.components.marketplaceData.inviteMembers;
const INVITE_LINK_GENERATE = 'INVITE_MEMBERS/INVITE_LINK_GENERATE';
const INVITE_LINK_GENERATE_FAILURE = 'INVITE_MEMBERS/INVITE_LINK_GENERATE_FAILURE';
const INVITE_LINK_GENERATE_SUCCESS = 'INVITE_MEMBERS/INVITE_LINK_GENERATE_SUCCESS';
const generateInviteLink = groupId => dispatch => {
  dispatch({
    type: INVITE_LINK_GENERATE,
    groupId
  });
  services.generateInviteLink(groupId, (error, response) => {
    if (error) {
      errorCodeHandler.getErrorMessage(error.code, err => {
        dispatch({
          type: INVITE_LINK_GENERATE_FAILURE,
          groupId,
          error
        });
        dispatch(dialogActions.showDialog({
          title: err.message,
          type: 'error'
        }));
      });
    } else {
      dispatch({
        type: INVITE_LINK_GENERATE_SUCCESS,
        token: response.token,
        expires: response.expires,
        groupId
      });
      dispatch(dialogActions.showDialog({
        title: 'Vi har uppdaterat inbjudningslänken'
      }));
    }
  });
};
setGlobal('svs.components.marketplaceData.inviteMembers.actions', {
  INVITE_LINK_GENERATE,
  INVITE_LINK_GENERATE_FAILURE,
  INVITE_LINK_GENERATE_SUCCESS,
  generateInviteLink
});

 })(window);